<template>
  <div class="modal fade bs-example-modal-lg"
       id="log-create-modal"
       tabindex="-1"
       role="dialog"
       aria-labelledby="myLargeModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myLargeModalLabel">
            Create Log
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body p-3">
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('subject')}]">
            <label class="col-sm-3 col-form-label">Subject :</label>
            <div class="col-sm-9">
              <input type="text"
                     v-model="form.subject"
                     class="form-control form-control-sm">
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('type')}]">
            <label class="col-sm-3 col-form-label">Type :</label>
            <div class="col-sm-9">
              <select class="form-select form-select-sm"
                      v-model="form.type">
                <option value="1">Call</option>
                <option value="2">Chat</option>
                <option value="3">อื่น ๆ</option>
              </select>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('type')}]">
            <label class="col-sm-3 col-form-label">Description :</label>
            <div class="col-sm-9">
              <textarea class="form-control form-control-sm"
                        rows="4"
                        v-model="form.description" />
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0 mt-2', {'error': errorFor('attachment')}]">
            <label class="col-sm-3 col-form-label">Attachment :</label>
            <div class="col-sm-9">
              <input type="file"
                     @change="readFile($event.target)"
                     class="form-control form-control-sm">
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('attachment')}]">
            <label class="col-sm-3 col-form-label">Case status :</label>
            <div class="col-sm-9">
              <input name="patient_status"
                     type="radio"
                     v-model="form.status"
                     id="patient_active" value="1">
              <label for="patient_active" class="me-30">Keep open</label>
              <input name="patient_status"
                     type="radio"
                     v-model="form.status"
                     id="patient_inactive" value="0">
              <label for="patient_inactive" class="me-30">Task close</label>
            </div>
          </div>
        </div>
        <div class="modal-footer text-center">
          <button type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal">ยกเลิก</button>

          <button class="btn btn-success"
                  :disabled="loading"
                  @click="submit">บันทึก</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: null,
      task: null,
      form: {
        subject: '',
        type: '',
        description: '',
        attachment: '',
        attachment_filename: '',
        status: ''
      }
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('log-create-modal'));
  },
  methods: {
    readFile(input) {
      if (input.files && input.files[0]) {
        this.form.attachment = input.files[0];
        this.form.attachment_filename = input.files[0].name;
      }
    },
    resetForm() {
      this.form.subject = '';
      this.form.type = '1';
      this.form.description = '';
      this.form.attachment = '';
      this.form.status = '';
      this.form.attachment_filename = '';
    },
    show(task) {
      this.resetForm();

      this.task = task;
      this.form.status = task.status;
      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    submit() {
      this.allErrors = null;
      this.loading = true;

      let formData = new FormData();

      formData.append('subject', this.form.subject);
      formData.append('type', this.form.type);
      formData.append('description', this.form.description);
      formData.append('attachment', this.form.attachment);
      formData.append('attachment_filename', this.form.attachment_filename);
      formData.append('status', this.form.status);

      this.axios.post('/visit-tasks/logs/' + this.task.id, formData)
        .then(() => {
          this.modal.hide();
          this.$emit('added');
        })
        .catch((err) => {
          this.allErrors = err.response.data.errors;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>