<template>
  <span class="badge badge-info-light" v-if="status == 1">Open</span>
  <span class="badge badge-primary-light" v-else-if="status == 2">Late</span>
  <span class="badge badge-success-light" v-else-if="status == 3">Done</span>
  <span class="badge badge-secondary-light" v-else-if="status == 4">Cancel</span>
</template>

<script>
export default {
  props: ['status']
}
</script>