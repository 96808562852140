<template>
  <div class="modal fade bs-example-modal-lg"
       id="new-log-added-modal"
       tabindex="-1"
       role="dialog"
       aria-labelledby="myLargeModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myLargeModalLabel">
            New Record Added
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body p-3">
          <div class="row">
            <div class="col-6">
              <div class="h-100 d-flex align-items-center rounded bg-gray-300 p-3"
                   @click="changeStatus(1)">
                <div class="text-center w-p100">
                  <i class="fas fa-clipboard-list fs-30 text-primary"></i>
                  <h6 class="mt-3">Keep Task Open</h6>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="h-100 d-flex align-items-center rounded bg-gray-300 p-3"
                   @click="changeStatus(3)">
                <div class="text-center w-p100">
                  <i class="fas fa-clipboard-check fs-30 text-primary"></i>
                  <h6 class="mt-3">Close Task</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: null,
      task: null,
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('new-log-added-modal'));
  },
  methods: {
    show(task) {
      this.task = task;

      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    changeStatus(status) {
      this.axios.post('/visit-tasks/status/' + this.task.id, {
        status: status
      }).then(() => {
        this.$emit('statusUpdated')
        this.hide();
      })
    }
  }
}
</script>