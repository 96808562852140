<template>
  <div class="modal fade bs-example-modal-lg"
       id="task-edit-modal"
       tabindex="-1"
       role="dialog"
       aria-labelledby="myLargeModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myLargeModalLabel">
            Update Task
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body p-3 pt-0">
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('date')}]">
            <label class="col-sm-2 col-form-label">Date</label>
            <div class="col-sm-10">
              <input type="date"
                     v-model="form.date"
                     class="form-control form-control-sm">
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('time')}]">
            <label class="col-sm-2 col-form-label">Time</label>
            <div class="col-sm-10">
              <input type="time"
                     v-model="form.time"
                     class="form-control form-control-sm">
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('title')}]">
            <label class="col-sm-2 col-form-label">Title</label>
            <div class="col-sm-10">
              <input type="text"
                     v-model="form.title"
                     class="form-control form-control-sm">
            </div>
          </div>
        </div>
        <div class="modal-footer text-center">
          <button type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal">Cancel</button>

          <button class="btn btn-success"
                  :disabled="loading"
                  @click="submit">Save</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: null,
      oldTask: null,
      form: {
        date: '',
        time: '',
        title: ''
      }
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('task-edit-modal'));
  },
  methods: {
    show(task) {
      this.oldTask = {...task};

      this.form.date = task.date_raw;
      this.form.time = task.time;
      this.form.time = task.time;
      this.form.title = task.title;

      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    submit() {
      this.allErrors = null;
      this.loading = true;

      this.axios.patch('/visit-tasks/' + this.oldTask.id, this.form)
        .then(() => {
          this.modal.hide();
          this.$emit('updated');
        })
        .catch((err) => {
          this.allErrors = err.response.data.errors;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  }
}
</script>