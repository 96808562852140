<template>
  <div class="content-header pb-0" v-if="task">
    <div class="row">
      <div class="col-6">
        <h3 class="page-title">Task Detail : {{ task.task_id }}</h3>
      </div>
    </div>
  </div>

  <hr>

  <div class="row" v-if="task">
    <div class="col-xl-4 col-12">
      <div class="box">
        <div class="box-header small-box-header">
          <h4 class="box-title">ข้อมูลผู้รับบริการ</h4>
        </div>
        <div class="box-body p-3">
          <div class="row">
            <div class="col-md-8">
              <table class="patient-details-table table table-borderless table-sm">
                <tbody>
                <tr>
                  <td>
                    <span style="color:#5156BE;">◆</span> HN
                  </td>
                  <td>: {{ task.patient.hn }}</td>
                </tr>
                <tr>
                  <td>
                    <span style="color:#5156BE;">◆</span> รหัสลูกค้า
                  </td>
                  <td>: {{ task.patient.patient_id }}</td>
                </tr>
                <tr>
                  <td>
                    <span style="color:#5156BE;">◆</span> ชื่อลูกค้า
                  </td>
                  <td>: {{ task.patient.full_name }}</td>
                </tr>
                <tr>
                  <td>
                    <span style="color:#5156BE;">◆</span> ชื่อเล่น
                  </td>
                  <td>: {{ task.patient.nick_name }}</td>
                </tr>
                <tr>
                  <td>
                    <span style="color:#5156BE;">◆</span> อายุ
                  </td>
                  <td>: {{ task.patient.age }} ปี</td>
                </tr>
                <tr>
                  <td>
                    <span style="color:#5156BE;">◆</span> หมู่เลือด
                  </td>
                  <td>: {{ task.patient.bloodGroup.name }}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="col-md-4 text-end">
              <img :src="task.patient.image ? task.patient.image : '/images/avatar-girl.png'"
                   onerror="this.src='/images/avatar-girl.png'"
                   class="w-p100 rounded">
            </div>
          </div>


          <table class="patient-details-table table table-borderless table-sm">
            <tbody>
            <tr>
              <td class="pt-3">
                <span style="color:#5156BE;">◆</span> โรคประจำตัว :
              </td>
              <td class="pt-3 text-end">
                <a href="#"
                   v-if="!editingCongenitalDisease"
                   @click.prevent="editCongenitalDisease">
                  <i class="fas fa-edit"></i> Edit
                </a>
                <a href="#"
                   v-else
                   @click.prevent="saveCongenitalDisease">
                  <i class="fas fa-save"></i> Save
                </a>
              </td>
            </tr>
            <tr>
              <td class="pt-1" colspan="2">
                <textarea class="form-control"
                          v-model="congenitalDiseaseForm.congenital_disease"
                          rows="3"
                          :disabled="!editingCongenitalDisease"></textarea>
              </td>
            </tr>
            <tr>
              <td class="pt-3">
                <span style="color:#5156BE;">◆</span> ประวัติการแพ้ยาและอาหาร :
              </td>
              <td class="pt-3 text-end">
                <a href="#"
                   v-if="!editingDrugAllergy"
                   @click.prevent="editDrugAllergy">
                  <i class="fas fa-edit"></i> Edit
                </a>
                <a href="#"
                   v-else
                   @click.prevent="saveDrugAllergy">
                  <i class="fas fa-save"></i> Save
                </a>
              </td>
            </tr>
            <tr>
              <td class="pt-1" colspan="2">
                <textarea class="form-control"
                          v-model="drugAllergyForm.drug_allergy"
                          rows="3"
                          :disabled="!editingDrugAllergy"></textarea>
              </td>
            </tr>
            <tr>
              <td colspan="2" class="text-center pt-3">
                <router-link :to="{name: 'patients_details', params: {id: task.patient.id}}"
                             class="btn btn-info-light btn-sm me-2">ดูรายละเอียด</router-link>
                <button type="button" class="waves-effect waves-light btn btn-primary-light btn-sm">Facial 3D</button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-xl-8 col-12" v-if="task">
      <div class="box">
        <div class="box-header small-box-header">
          <h4 class="box-title">Details</h4>
        </div>
        <div class="box-body p-3">
          <div class="form-group form-group-sm row mb-0">
            <label class="col-sm-2 col-form-label">Title :</label>
            <div class="col-sm-6">
              <input type="text"
                     v-model="task.title"
                     class="form-control form-control-sm" disabled>
            </div>
            <label class="col-sm-2 col-form-label">Status :</label>
            <div class="col-sm-2">
              <select class="form-select form-select-sm"
                      v-model="task.status" disabled>
                <option value="1">Open</option>
                <option value="2">Late</option>
                <option value="3">Done</option>
                <option value="4">Cancel</option>
              </select>
            </div>
          </div>
          <div class="form-group form-group-sm row mb-0">
            <label class="col-sm-2 col-form-label">Description :</label>
            <div class="col-sm-10">
              <textarea class="form-control form-control-sm"
                        v-model="task.description"
                        rows="4" disabled />
            </div>
          </div>
        </div>
      </div>
      <div class="box">
        <div class="box-header small-box-header">
          <div class="row">
            <div class="col-6">
              <h4 class="box-title">Log</h4>
            </div>
            <div class="col-6 text-end">
              <button class="btn btn-sm btn-primary-light"
                      @click="$refs.logCreateModal.show(task)">Create</button>
            </div>
          </div>
        </div>

        <div class="box-body p-1">
          <template v-if="task.logs.length">
            <div class="box" v-for="log in task.logs"
                 :key="'log_'+log.id">
              <div class="box-body p-3 bg-gray-300">
                <h6 class="text-primary">
                  <i class="fas fa-comment-alt"></i> {{ log.subject }}
                </h6>
                <p>{{ log.description }}</p>
                <p class="text-muted">{{ log.created_at }} by {{ log.updatedBy.full_name }}</p>

                <a :href="log.attachment"
                   target="_blank"
                   v-if="log.attachment">
                  <i class="fas fa-file"></i> {{ log.attachment_filename }}
                </a>
              </div>
            </div>
          </template>

          <div class="h-100 d-flex align-items-center" v-else>
            <div class="text-center w-p100">
              <i class="fas fa-folder-open fs-40 text-muted"></i>
              <h4 class="mt-3">No Record Yet</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <log-create-modal @added="newLogAdded"
                    ref="logCreateModal" />
  <new-log-added-modal @statusUpdated="loadTask"
                       ref="newLogAddedModal" />
</template>

<script>
import LogCreateModal from "@/views/task/components/LogCreateModal.vue";
import NewLogAddedModal from "@/views/task/components/NewLogAddedModal.vue";
export default {
  components: {LogCreateModal, NewLogAddedModal},
  data() {
    return {
      task: null,
      editingCongenitalDisease: false,
      editingDrugAllergy: false,
      congenitalDiseaseForm: {
        congenital_disease: '',
      },
      drugAllergyForm: {
        drug_allergy: '',
      }
    }
  },
  created() {
    this.loadTask();
  },
  methods: {
    newLogAdded() {
      this.$refs.newLogAddedModal.show(this.task);
      this.loadTask();
    },
    loadTask() {
      this.task = null;

      this.axios.get('/visit-tasks/' + this.$route.params.id)
        .then(res => {
          this.task = res.data.data;
          this.congenitalDiseaseForm.congenital_disease = this.task.patient.congenital_disease;
          this.drugAllergyForm.drug_allergy = this.task.patient.drug_allergy;
        })
    },
    editCongenitalDisease() {
      this.congenitalDiseaseForm.congenital_disease = this.task.patient.congenital_disease;
      this.editingCongenitalDisease = true;
    },
    saveCongenitalDisease() {
      this.congenitalDiseaseForm.patient_id = this.task.patient.id;

      this.axios.post('/patients/congenital-disease', this.congenitalDiseaseForm)
        .then(() => {
          this.editingCongenitalDisease = false;
        })
    },
    editDrugAllergy() {
      this.drugAllergyForm.drug_allergy = this.task.patient.drug_allergy;
      this.editingDrugAllergy = true;
    },
    saveDrugAllergy() {
      this.drugAllergyForm.patient_id = this.task.patient.id;

      this.axios.post('/patients/drug-allergy', this.drugAllergyForm)
        .then(() => {
          this.editingDrugAllergy = false;
        })
    },
  }
}
</script>