<template>
  <div class="content-header">
    <div class="d-flex align-items-center">
      <div class="me-auto">
        <h3 class="page-title">Task List</h3>
        <div class="d-inline-block align-items-center">
          <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#"><i class="mdi mdi-home-outline"></i></a></li>
              <li class="breadcrumb-item active" aria-current="page">Task List</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="box">
        <div class="box-header small-box-header">
          <div class="row">
            <div class="col-md-8">
              <div class="input-group">
                <input type="text"
                       @keyup="searchText($event.target.value)"
                       class="form-control"
                       placeholder="ค้นหาด้วยการระบุ Task ID หรือ Title หรือ Case หรือ Patient หรือ Doctor">
                <span class="input-group-text"><i class="ti-search"></i></span>
              </div>
            </div>
            <div class="col-md-2">
              <select class="form-select"
                      @change="refreshData"
                      v-model="queryParams.type">>
                <option value="">Type: All Type</option>
                <option value="Consult">Consult</option>
                <option value="Follow-up">Follow-up</option>
              </select>
            </div>
            <div class="col-md-2">
              <select class="form-select"
                      @change="refreshData"
                      v-model="queryParams.status">
                <option value="">{{ $t('case.status_all') }}</option>
                <option value="1">Open</option>
                <option value="2">Late</option>
                <option value="3">Done</option>
                <option value="4">Cancel</option>
              </select>
            </div>
          </div>
        </div>
        <div class="box-body p-3">
          <table-component :columns="columns"
                           :rows="tasks"
                           :sortable="sortable"
                           @sorting="sorting"
                           :pagination="pagination"
                           @search="search"
                           :search="false"
                           @pageChanged="pageChanged">

            <template v-slot:status="{ row }">
              <task-status :status="row.status" />
            </template>

            <template v-slot:action="{ row }">
              <router-link :to="{name: 'tasks_view', params: {id: row.id}}"
                 class="text-muted">
                <i class="fas fa-eye"></i>
              </router-link> |
              <a href="#"
                 @click.prevent="$refs.taskEditModal.show(row)"
                 class="text-muted">
                <i class="fas fa-pen"></i>
              </a> |
              <a href="#"
                 @click.prevent="deleteData(row)"
                 class="text-muted">
                <i class="fas fa-trash"></i>
              </a>
            </template>
          </table-component>
        </div>
      </div>
    </div>
  </div>

  <task-edit-modal @updated="loadData"
                   ref="taskEditModal" />
</template>

<script>
import TableComponent from "@/components/TableComponent";
import TaskStatus from "./TaskStatus.vue";
import TaskEditModal from "@/views/task/TaskEditModal.vue";
export default {
  components: {TableComponent, TaskStatus, TaskEditModal},
  data() {
    return {
      tasks: [],
      doctors: [],
      pagination: null,
      queryParams: {
        status: '',
        type: '',
        page: 1,
        search: '',
        sort: 'created_at',
        sort_order: 'desc',
        per_page: 10
      },
      sortable: {
        order: 'created_at',
        sort: 'desc'
      },
      columns: [
        {
          label: "Task ID",
          field: "task_id",
          sortable: true,
          searchable: true,
        },
        {
          label: "Title",
          field: "title",
          sortable: true,
          searchable: true,
        },
        {
          label: "Type",
          field: "type",
          sortable: true,
          searchable: false,
        },
        {
          label: "Case",
          field: "visitCase.case_no",
          sortable: false,
          searchable: true
        },
        {
          label: "Patient",
          field: "patient.full_name",
          sortable: false,
          searchable: true,
        },
        {
          label: "Doctor",
          field: "doctor.full_name",
          sortable: false,
          searchable: true,
          display(row) {
            return row.doctor ? row.doctor.full_name : '';
          }
        },
        {
          label: "Due Date",
          field: 'date',
          sortable: true,
          searchable: false,
        },
        {
          label:  this.$t('case.status'),
          field: 'status',
          sortable: true,
          searchable: false,
          slot: true
        },
        {
          label:  this.$t('case.action'),
          field: 'action',
          sortable: false,
          searchable: false,
          slot: true,
          class: ['text-end']
        }
      ],
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.tasks = [];

      this.axios.get('/visit-tasks?case=1&patient=1&doctor=1', {params: this.queryParams})
        .then((response) => {
          this.tasks = response.data.data;
          this.pagination = response.data.meta;
        })
    },
    deleteData(row) {
      this.$swal({
        title: this.$t('patients.are_you_sure_want_to_delete'),
        text: this.$t('patients.you_wont_be_able_to_revert_this'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        confirmButtonColor: '#dd4b39',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.delete('/visit-tasks/'+row.id).then(() => {
            this.loadData();
            this.$swal.fire(
              'Deleted',
              'Visit task has been deleted!',
              'success'
            )
          });
        }
      });
    },
  }
}
</script>